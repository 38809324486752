.input-radio-comp {
    text-align: left;
}

.input-radio-comp .ant-radio {
    vertical-align: middle;
}

.input-radio-comp .labelbox {
    overflow: hidden;
    display: inline-table;
}

.input-radio-comp span.label2 {
    position: relative;
    font-size: 16px;
    display: block;
    color: rgba(0,0,0,.5);
}
